<template>
  <div>
    <!-- Start card wrapper -->
    <v-card class="SearchWrapper" persistent>
      <v-layout width="100%" row :algin-start="mdUp" :align-center="!mdUp">
        <!-- Start search title -->
        <v-flex xs12 sm3 style="margin-right: 1%">
          <p class="FieldLabel"></p>
          <!-- End search title -->
          <!-- Start search text field -->
          <v-text-field class="input-busqueda-general" outlined dense clearable v-model="sSearch"
            placeholder="Búsqueda general" append-icon="mdi-magnify"></v-text-field>
        </v-flex>
        <v-flex xs12 sm2 style="margin-right: 1%">
          <p class="FieldLabel"></p>
          <v-select v-model="statusChosen" clearable item-text="sName" item-value="sProjectStatusId"
            :items="itemsStatus" class="custom-placeholder" background-color="#FAFAFA" dense :placeholder="'Estatus'"
            outlined color="primary">
          </v-select>
        </v-flex>
        <v-flex v-if="aAllows.includes('Projects')" xs12 sm3 style="margin-right: 1%">
          <p class="FieldLabel"></p>
          <v-select v-model="selectPF" item-text="state" item-value="value" :items="itemsPF" class="custom-placeholder"
            background-color="#FAFAFA" dense :placeholder="'Proyectos'" outlined color="primary">
          </v-select>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm2 class="text-right">
          <p class="FieldLabel"></p>
          <v-btn class="text-Poppins" color="primary" style="
              color: black;
              box-shadow: 0px 20px 40px #00000029;
              border-radius: 5px;
              letter-spacing: 1px;
              font-weight: 600;
            " @click="setAddProyect()" v-if="
              aAllows.includes('Projects') || aAllows.includes('Administrator')
            ">Nuevo proyecto
          </v-btn>
        </v-flex>

        <v-dialog persistent class="modalNuevoProyecto" v-model="dialog" width="950" style="z-index: 999999999999">
          <v-card style="border-radius: 10px; padding-top: 15px; padding-bottom: 15px">
            <v-icon @click="close()" color="#000000" style="position: absolute; right: 25px">mdi-close
            </v-icon>

            <v-card-title class="headline justify-center" style="text-align: center">
              Nuevo proyecto
            </v-card-title>
            <hr style="height: 1px; background-color: #ffce00; border: none" />
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="nombreProyecto" class="text-Poppins" label="Nombre del proyecto"
                      style="margin-bottom: -10px"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-select v-model="residente" class="text-Poppins" item-text="sFullName" item-value="sUserId"
                      :items="items" label="Residente" style="margin-bottom: -10px"></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <!-- <v-text-field
                      v-model="montoContrato"
                      class="text-Poppins"
                      type="number"
                      prefix="$"
                      suffix="MXN"
                      placeholder="0.00"
                      label="Monto de contrato"
                      style="margin-bottom: -10px"
                      :min="0"
                      @keypress="isNumberMoney($event)"
                      step="0.01"
                    ></v-text-field> -->
                    <input-currency-global :flagModal="dialog" :bDense="true" labelGlobal="Monto de contrato"
                      @changeMontoGlobal="changeMontoGlobal" :modelValue="montoContrato" />
                  </v-col>
                  <!-- <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="presupuesto"
                      class="text-Poppins"
                      label="Presupuesto"
                      type="number"
                      suffix="mxn"
                      style="margin-bottom: -10px"
                    >
                    </v-text-field>
                  </v-col> -->

                  <v-col cols="12" sm="12" md="12">
                    <p style="font-weight: 600; color: black; font-size: 18px" class="text-Poppins">
                      Presupuesto
                    </p>
                    <v-card style="border-radius: 10px">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" style="
                                  max-width: 50%;
                                  min-width: 50%;
                                  width: 50%;
                                ">
                                Categoría
                              </th>
                              <th class="text-left" style="
                                  max-width: 30%;
                                  min-width: 30%;
                                  width: 30%;
                                ">
                                Monto
                              </th>
                              <th style="
                                  max-width: 20%;
                                  min-width: 20%;
                                  width: 20%;
                                "></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in itemsCategorias" :key="index">
                              <td>{{ item.sName }}</td>
                              <td>
                                $ {{ mask(item.dBudget) }} MXN
                                <!-- <input
                                v-model="item.dBudget"
                                :key="index"
                                prefix="$"
                                suffix="MXN"
                                type="number"
                                class="input-table-materiales"
                                min="0"
                              /> -->
                              </td>
                              <td>
                                <v-icon color="red" @click="deleteItemCategory(index)">mdi-close</v-icon>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      <div style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        ">
                        <hr style="
                            width: 97%;
                            border: 1px solid #fafafa;
                            margin-top: 50px;
                          " />
                      </div>
                      <div style="border-bottom: 2px solid #e0e0e0 !important">
                        <v-form>
                          <v-container>
                            <v-row>
                              <v-col cols="12" md="6">
                                <v-text-field v-model="nombreCategoria" class="input-categoria" outlined
                                  label="Nombre de la categoría" dense maxlength="50"></v-text-field>
                              </v-col>

                              <v-col cols="12" md="4">
                                <!-- <v-text-field
                                  v-model="montoCategoria"
                                  type="number"
                                  class="input-categoria"
                                  label="Monto"
                                  :min="1"
                                  :max="999999999"
                                  outlined
                                  dense
                                  prefix="$"
                                  suffix="MXN"
                                  @keypress="isNumberMoney($event)"
                                ></v-text-field> -->
                                <input-currency-global :flagModal="dialog" :bOutlined="true" :bDense="true"
                                  :emptyValue="emptyValueContrato" classDynamic="input-categoria"
                                  @changeMontoGlobal="changeMontoCategoria" :modelValue="montoContrato" />
                              </v-col>

                              <v-col cols="12" md="2">
                                <v-btn style="
                                    background-color: #ffce00;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 12px;
                                    font-weight: 600;
                                    border-radius: 10px;
                                  " :disabled="!validateAnadir" @click="setCategorias">Añadir</v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-form>
                      </div>
                      <div class="mt-3" style="
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                          padding-right: 20px;
                        ">
                        <p style="
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 600;
                          ">
                          SUBTOTAL
                          <span style="color: #63e3b2; margin-left: 10px">${{ sumatoriaCategorias() }} MXN</span>
                        </p>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-layout style="height: 50px !important" justify-end>
              <v-card-actions justify-end class="pa-6">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn x-large color="black" width="175" outlined class="text-Poppins" @click="close()" style="
                          height: 40px;
                          font-size: 13px;
                          font-weight: 600;
                          border-radius: 5px;
                        ">Cancelar
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-btn x-large width="175" color="primary" class="text-Poppins" style="
                          height: 40px;
                          color: black;
                          box-shadow: 0px 20px 40px #00000029;
                          border-radius: 5px;
                          font-size: 13px;
                          font-weight: 600;
                        " :disabled="!validateForm" @click="createProject" :loading="loadingButton">Crear
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-layout>
          </v-card>
        </v-dialog>
        <!-- End search text field -->
      </v-layout>
    </v-card>
    <!-- End card wrapper -->
  </div>
</template>
<script>
import InputCurrencyGlobal from "../../core/Input-Currency-Global.vue";

export default {
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
  data() {
    return {
      items: [],

      dialog: false,
      areaChosen: null,
      statusChosen: null,
      // store search input
      sSearch: "",
      bMenuFinal: false,
      bMenuInitial: false,
      dateStart: null,
      dateEnd: null,
      nombreProyecto: "",
      residente: "",
      montoContrato: 0,
      emptyValueContrato: false,

      presupuesto: "",
      itemsStatus: [],
      itemsCategorias: [],
      nombreCategoria: "",
      montoCategoria: "",
      loadingButton: false,
      permisionUser: localStorage.getItem("permisionUser"),

      //project filter
      selectPF: {
        state: "Todos los proyectos",
        value: 1,
      },
      itemsPF: [
        {
          state: "Todos los proyectos",
          value: 1,
        },
        {
          state: "Mis proyectos",
          value: 2,
        },
      ],
      aAllows: this.$store.state.aAllows.split(","),
    };
  },
  components: {
    InputCurrencyGlobal,
  },
  mounted() {
    this.getUsers();
    this.getStatus();
  },
  beforeMount() {
    this.$store.commit("setSearch", "");
    this.$store.commit("setStatus", null);
    this.$store.commit("setStartDate", null);
    this.$store.commit("setEndDate", null);
    //  this.$store.commit("setDebtDays", null);
  },
  beforeDestroy() {
    this.$store.commit("setStatus", null);
    this.$store.commit("setIProjectFilter", 1);
    this.$store.commit("setArea", null);
    this.$store.commit("setStartDate", null);
    this.$store.commit("setEndDate", null);



  },
  methods: {
    isNumberMoney(evt) {
      if (evt.target.value.includes(".")) {
        let sDecimal = "";
        sDecimal = evt.target.value.substring(
          evt.target.value.indexOf("."),
          evt.target.value.length
        );
        if (sDecimal.length > 2) {
          evt.preventDefault();
        }
      }
      if (evt.which === 101 || evt.which === 45) {
        evt.preventDefault();
      }
    },
    isNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    close() {
      this.nombreProyecto = "";
      this.montoContrato = 0;
      this.residente = "";
      this.nombreCategoria = "";
      this.montoCategoria = "";
      this.itemsCategorias = [];
      this.dialog = false;
    },
    getStatus() {
      // get status
      db.get(`${uri}/api/v1/project_status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsStatus = resp.data.status;
          this.statusChosen = this.itemsStatus[1].sProjectStatusId;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    changeMontoGlobal(value) {
      if (value !== "") {
        this.montoContrato = parseFloat(value);
      } else {
        this.montoContrato = null;

      }
    },
    changeMontoCategoria(value) {
      this.montoCategoria = value;
    },
    //Send new project
    setAddProyect() {
      this.$router.push({ name: "AddProyect" }).catch((e) => { });
    },
    createProject() {
      this.loadingButton = true;

      const payload = {
        sName: this.nombreProyecto,
        sUserId: this.residente,
        dBudget: 0,
        dContractAmount: this.montoContrato,
        aCategories: this.itemsCategorias,
      };
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          contentType: "application/x-www-form-urlencoded;charset=utf-8",
        },
      };
      //post credentials and get access token from laravel backend
      db.post(`${uri}/api/v1/projects`, payload, config)
        .then((response) => {
          this.loadingButton = false;
          this.nombreProyecto = "";
          this.residente = "";
          this.presupuesto = "";
          const alert = {
            color: "green",
            text: response.data.message,
          };
          this.close();
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;
          this.loadingButton = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    getUsers() {
      // get users
      db.get(`${uri}/api/v1/users?iPageNumber=1&isProjectUser=1`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.items = resp.data.users;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    deleteItemCategory(e) {
      this.itemsCategorias.splice(e, 1);
    },
    sumatoriaCategorias() {
      var sum = 0;
      for (let i = 0; i < this.itemsCategorias.length; i++) {
        const element = this.itemsCategorias[i];
        sum = parseFloat(element.dBudget) + parseFloat(sum);
      }
      return this.mask(sum);
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    setCategorias() {
      const arr = {
        sName: this.nombreCategoria,
        dBudget: this.montoCategoria,
      };
      this.itemsCategorias.push(arr);
      this.clearItemsCategorias();
    },
    clearItemsCategorias() {
      this.nombreCategoria = "";
      this.montoCategoria = 0;
      this.emptyValueContrato = !this.emptyValueContrato;
    },
  },
  computed: {
    validateForm() {
      return (
        this.nombreProyecto.trim() !== "" &&
        this.residente !== "" &&
        this.montoContrato !== null &&
        this.itemsCategorias.length >= 1
      );
    },
    validateAnadir() {
      return (
        this.nombreCategoria.trim() !== "" &&
        this.montoCategoria !== "" &&
        this.montoCategoria >= 1
      );
    },
  },
  watch: {
    statusChosen: function () {
      this.$store.commit("setStatus", this.statusChosen);
    },
    selectPF: function () {
      this.$store.commit("setIProjectFilter", this.selectPF);
    },
    areaChosen: function () {
      this.$store.commit("setArea", this.areaChosen);
    },
    dateStart: function () {
      this.dateEnd = null;
      this.$store.commit("setStartDate", this.dateStart);
    },
    dateEnd: function () {
      this.$store.commit("setEndDate", this.dateEnd);
    },
    // set state to local search
    sSearch: lodash.debounce(function (val) {
      this.$store.commit("setSearch", this.sSearch);
    }, 800),
  },
};
</script>
<style>
.v-application--is-ltr .v-text-field__suffix {
  color: #707070 !important;
}

.input-busqueda-general .v-input__slot fieldset {
  border-radius: 10px;
  background-color: #fafafa;
  border: 1px solid #f6f6f6;
}

.input-busqueda-general .v-input__slot input {
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  color: #707070 !important;
}

.input-busqueda-general .v-input__slot input::placeholder {
  color: #707070;
}

.custom-placeholder .v-select__slot .v-select__selections input::placeholder {
  color: #707070;
}

.input-busqueda-general .mdi-magnify,
.mdi-menu-down {
  font-size: 19px;
  color: #2e2e2e;
}

.FieldLabel {
  text-align: left;
  font: normal normal normal 12px/14px "Poppins", sans-serif;
  margin-bottom: 0px !important;
  margin-left: 20px;
  z-index: 100;
  color: #008b83;
  opacity: 1;
}

.custom-placeholder fieldset {
  border: 1px solid #f6f6f6;
}

.text-Poppins {
  font-family: "Poppins", sans-serif !important;
}

.custom-placeholder input::placeholder {
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}

.custom-placeholder input::-moz-placeholder {
  text-align: left;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #000000;
}
</style>
<style
  scoped>
.input-categoria>>>.v-input__slot {
  /* background-color: #ce0000; */
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px;
  font-size: 14px;
}

.input-categoria>>>input:-webkit-autofill,
.input-categoria>>>input:-webkit-autofill:hover,
.input-categoria>>>input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 30px #f9f9f9 inset !important;
  -webkit-box-shadow: 0 0 0px 30px #f9f9f9 inset !important;
}

.input-categoria>>>.theme--light.v-label {
  color: #999898;
  border-color: #e5e5e5 !important;
}

/* inputs global style */
.input-categoria .v-text-field__details {
  display: none;
}

.input-categoria .v-input__slot {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #85858529 !important;
  border-radius: 9px !important;
  font-size: 13px;
}

.input-categoria fieldset {
  border: 1px solid #e5e5e5 !important;
}

.input-categoria input {
  color: #928d8d !important;
  letter-spacing: 0px !important;
}

.searchTextField {
  margin-top: -28px;
}

.SearchWrapper {
  max-height: 80px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px transparent !important;
  border-radius: 30px !important;
  width: 100%;
}
</style>